import SliderC from '../../components/inicio/sliderC';
import AcercadeC from '../../components/inicio/acercadeC';
import EspecialesC from '../../components/inicio/especialesC';
import EventosC from '../../components/inicio/eventosC';


import "../../components/inicio/inicio.css"


import "bootstrap/dist/css/bootstrap.min.css";
import Menu1 from '../../components/inicio/Menu1';
import Footer1 from '../../components/inicio/Footer1';

import Testimonial from '../../components/inicio/Testimonial';
import Gallery from '../../components/inicio/Gallery';

function inicio() {
  return (
    <div>
      <SliderC/>
      <AcercadeC/>
      <EspecialesC/>
      <EventosC />
      
      <Gallery/>
      <Testimonial/>
      <Footer1/>

    </div>
  );
}

export default inicio;
