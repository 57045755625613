import "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";

import './Point.css';


import img4 from '../../../Assets/g3.jpeg';
import header from "../header/headerC";

function About() {
    return (
        <>
      
            <section id="about" className="about">
                <div className="container" >

                    <div className="row">
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="about-img">
                                <img src={img4} alt="" />
                            </div>
                        </div>
                        <div className=" titulo-seccion ">
                            <h1 style={{textAlign:'start'}}>About Us</h1>
                        </div>
                        <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
                        
                            
                            <p className="fst-italic" style={{ textAlign: 'left' }}>
                            B1 Pub is a casual dining pub offering a vibrant ambience and a variety of sumptuous delicacies. B1 Pub serves North Indian and Italian cuisine. The eclectic bar menu offers an array of drinks and spirits to choose from. B1 Pub is the perfect place to relax and unwind. It is located in Vidyaranyapura, West Bangalore and stands Opposite Canara Bank.
                            </p>
                            <div className="points">
                            <ul>
                                <li style={{ textAlign: 'left'}}><i className="bi bi-check-circle-fill" style={{ fontSize: "1.5em", color: '#cda45e'}}></i> Air Conditioned</li>
                                <li style={{ textAlign: 'left'}}><i className="bi bi-check-circle-fill" style={{ fontSize: "1.5em", color: '#cda45e'}}></i>  Parking </li>
                                <li style={{ textAlign: 'left'}}><i className="bi bi-check-circle-fill" style={{ fontSize: "1.5em", color: '#cda45e'}}></i>  Live Screening</li>
                               
                            </ul>
                            <div className="padd"></div>
                            <ul>
                                <li style={{ textAlign: 'left'}}><i className="bi bi-check-circle-fill" style={{ fontSize: "1.5em", color: '#cda45e'}}></i> Free Wi-Fi</li>
                                <li style={{ textAlign: 'left'}}><i className="bi bi-check-circle-fill" style={{ fontSize: "1.5em", color: '#cda45e'}}></i> Snack Bar</li>
                                <li style={{ textAlign: 'left'}}><i className="bi bi-check-circle-fill" style={{ fontSize: "1.5em", color: '#cda45e'}}></i> Comfortable Seating</li>
                            </ul>

                            </div>
                            
                            
                        </div>
                    </div>

                </div>
            </section>
        </>
    );
}

export default About;
