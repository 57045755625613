import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Inicio from "../layout/users/inicio";
import Menu from "../layout/users/menu";
import Contacto from "../layout/users/contacto";
import Header from "../components/header/headerC";
import Footer from "../components/footer/footerC";
import {Gallery1} from "../components/Gallery1";
import About from "../components/inicio/About";

function UserLayout() {
  return (
    <div>
      <Header />
      <Routes>
        <Route path="/" element={<Inicio />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/about" element={<About />} />
        <Route path="/gallery" element={<Gallery1 />} />
        <Route path="/contact" element={<Contacto />} />
      </Routes>
      <Footer />
    </div>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<UserLayout />} />
      </Routes>
    </BrowserRouter>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);

export default App;
