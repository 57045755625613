import React from 'react';

import './menu.css';
import imgg1 from '../../../Assets/drink3.jpeg';
import imgg2 from '../../../Assets/food1.jpeg';
import imgg3 from '../../../Assets/drink2.jpeg';

const menucontainer = () => {





  return (
    <>
    <br/>
    <br/>
    <br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<div className='m-all' >
<div className=" titulo-seccion ">
                            <h1 style={{textAlign:'center'}}>Our Menu</h1>
                        </div>
        <br/>
        <br/>
   <div className='m-menu'>

  

        <div className='menu1'>
      
             <h2> B1 SPECIAL COCKTAILS</h2>
              <br/>
              
             <div className='menu11'>
                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> A Short Trip To Hell</h5>
                    </div>
                    <div style={{paddingLeft:'160px'}}></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 380</h6>
                    </div>
               
               
                </div>
                <hr  style={{color:'white',width:'100%'}}/>
               
                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Pina De plata </h5>
                    </div>
                    <div style={{paddingLeft:'160px'}}></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 425</h6>
                    </div>
               
               
                </div>
                <hr  style={{color:'white',width:'100%'}}/>
               

                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Bint Bum </h5>
                    </div>
                    <div style={{paddingLeft:'160px'}}></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. $05</h6>
                    </div>
               
               
                </div>
                <hr  style={{color:'white',width:'100%'}}/>
               

                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Rock'n roll </h5>
                    </div>
                    <div style={{paddingLeft:'160px'}}></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 460</h6>
                    </div>
               
               
                </div>
                <hr  style={{color:'white',width:'100%'}}/>
               
                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> jager Bomb </h5>
                    </div>
                    <div style={{paddingLeft:'160px'}}></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 600</h6>
                    </div>
               
               
                </div>
                <hr  style={{color:'white',width:'100%'}}/>
               
                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Planter Punch </h5>
                    </div>
                    <div style={{paddingLeft:'160px'}}></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 380</h6>
                    </div>
               
               
                </div>
               
                 
               
             </div>
        </div>

        <div className='menu1'>
             <h2> MOCKTAILS</h2>
              <br/>
              
             <div className='menu11'>
                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Blue Top </h5>
                    </div>
                    <div style={{paddingLeft:'160px'}}></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 215</h6>
                    </div>
               
               
                </div>
                <hr  style={{borderTop:'1px dotted gray',width:'100%'}}/>
               
                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Kiwi delight </h5>
                    </div>
                    <div style={{paddingLeft:'160px'}}></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 200</h6>
                    </div>
               
               
                </div>
                <hr  style={{color:'white',width:'100%'}}/>
               

                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Thai Sunset</h5>
                    </div>
                    <div style={{paddingLeft:'160px'}}></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 215</h6>
                    </div>
               
               
                </div>
                <hr  style={{color:'white',width:'100%'}}/>
               

                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Pink Berry Colada </h5>
                    </div>
                    <div style={{paddingLeft:'160px'}}></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 215</h6>
                    </div>
               
               
                </div>
                <hr  style={{color:'white',width:'100%'}}/>
               
                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Virgin Colada </h5>
                    </div>
                    <div style={{paddingLeft:'160px'}}></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 215</h6>
                    </div>
               
               
                </div>
                <hr  style={{color:'white',width:'100%'}}/>
               
                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Mango Boom </h5>
                    </div>
                    <div style={{paddingLeft:'160px'}}></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 215</h6>
                    </div>
               
               
                </div>
               
               
                
               
               
             </div>
        </div>
       

        </div>
         
         <br/>
         <br/>
         <br/>
         <br/>

          

         <div className='m-menu'>

  

<div className='menu1'>

     <h2> DRAUGHT BEER</h2>
      <br/>
      
     <div className='menu11'>
        <div className='menu111'>
            <div className='m-1'>
            <h5 style={{color:'gray'}}>KF Pitcher/ Mug</h5>
            </div>
            <div style={{paddingLeft:'160px'}}></div>
            <div className='m-2'>
            <h6 style={{color:'gray'}}> Rs. 699/140</h6>
            </div>
       
       
        </div>
        <hr  style={{color:'white',width:'100%'}}/>
       
        <div className='menu111'>
            <div className='m-1'>
            <h5 style={{color:'gray'}}> Bira White Pitcher/ 500ml/ Mug</h5>
            </div>
            <div style={{paddingLeft:'160px'}}></div>
            <div className='m-2'>
            <h6 style={{color:'gray'}}> Rs. 745/250/150</h6>
            </div>
       
       
        </div>
        <hr  style={{color:'white',width:'100%'}}/>
       

        <div className='menu111'>
            <div className='m-1'>
            <h5 style={{color:'gray'}}> Bira Blonde Pitcher/ 500ml/ Mug</h5>
            </div>
            <div style={{paddingLeft:'160px'}}></div>
            <div className='m-2'>
            <h6 style={{color:'gray'}}> Rs. 720/240/145</h6>
            </div>
       
       
        </div>
        <hr  style={{color:'white',width:'100%'}}/>
       

        <div className='menu111'>
            <div className='m-1'>
            <h5 style={{color:'gray'}}> Bira Gold Pitcher/ 500ml/ Mug </h5>
            </div>
            <div style={{paddingLeft:'160px'}}></div>
            <div className='m-2'>
            <h6 style={{color:'gray'}}> Rs. 720/240/145</h6>
            </div>
       
       
        </div>
        <hr  style={{color:'white',width:'100%'}}/>
       
        <div className='menu111'>
            <div className='m-1'>
            <h5 style={{color:'gray'}}> Budweiser Premium Pitcher/ 500ml/ Mug</h5>
            </div>
            <div style={{paddingLeft:'160px'}}></div>
            <div className='m-2'>
            <h6 style={{color:'gray'}}> Rs. 600/220/125</h6>
            </div>
       
       
        </div>
        <hr  style={{color:'white',width:'100%'}}/>
       
        <div className='menu111'>
            <div className='m-1'>
            <h5 style={{color:'gray'}}> Budweiser Magnum Pitcher/ 500ml/ Mug </h5>
            </div>
            <div style={{paddingLeft:'160px'}}></div>
            <div className='m-2'>
            <h6 style={{color:'gray'}}> Rs. 625/240/135</h6>
            </div>
       
       
        </div>
       
         
       
     </div>
</div>

<div className='menu1'>
     <h2> REFRESHERS</h2>
      <br/>
      
     <div className='menu11'>
        <div className='menu111'>
            <div className='m-1'>
            <h5 style={{color:'gray'}}> Energy Drink </h5>
            </div>
            <div style={{paddingLeft:'160px'}}></div>
            <div className='m-2'>
            <h6 style={{color:'gray'}}> Rs. 180</h6>
            </div>
       
       
        </div>
        <hr  style={{borderTop:'1px dotted gray',width:'100%'}}/>
       
        <div className='menu111'>
            <div className='m-1'>
            <h5 style={{color:'gray'}}> Ginger Ale</h5>
            </div>
            <div style={{paddingLeft:'160px'}}></div>
            <div className='m-2'>
            <h6 style={{color:'gray'}}> Rs. 99</h6>
            </div>
       
       
        </div>
        <hr  style={{color:'white',width:'100%'}}/>
       

        <div className='menu111'>
            <div className='m-1'>
            <h5 style={{color:'gray'}}> Diet Cola</h5>
            </div>
            <div style={{paddingLeft:'160px'}}></div>
            <div className='m-2'>
            <h6 style={{color:'gray'}}> Rs. 99</h6>
            </div>
       
       
        </div>
        <hr  style={{color:'white',width:'100%'}}/>
       

        <div className='menu111'>
            <div className='m-1'>
            <h5 style={{color:'gray'}}> Tonic Water</h5>
            </div>
            <div style={{paddingLeft:'160px'}}></div>
            <div className='m-2'>
            <h6 style={{color:'gray'}}> Rs. 99</h6>
            </div>
       
       
        </div>
        <hr  style={{color:'white',width:'100%'}}/>
       
        <div className='menu111'>
            <div className='m-1'>
            <h5 style={{color:'gray'}}> Canned Juice</h5>
            </div>
            <div style={{paddingLeft:'160px'}}></div>
            <div className='m-2'>
            <h6 style={{color:'gray'}}> Rs. 90</h6>
            </div>
       
       
        </div>
        <hr  style={{color:'white',width:'100%'}}/>
       
        <div className='menu111'>
            <div className='m-1'>
            <h5 style={{color:'gray'}}> Fresh Lime Soda/Water </h5>
            </div>
            <div style={{paddingLeft:'160px'}}></div>
            <div className='m-2'>
            <h6 style={{color:'gray'}}> Rs. 80</h6>
            </div>
       
       
        </div>
       
       
        
       
       
     </div>
</div>


</div>

          




         <br/>
         <br/>
         <br/>
         <br/>


        <div className='m-menu'>
        <div className='menu1'>
             <h2>Instant Fixes</h2>
              <br/>
              
             <div className='menu11'>
                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> B52 </h5>
                    </div>
                    <div style={{paddingLeft:'160px'}}></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 380</h6>
                    </div>
               
               
                </div>
                <hr  style={{color:'white',width:'100%'}}/>
               
                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Kamakazi</h5>
                    </div>
                    <div style={{paddingLeft:'160px'}}></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 250</h6>
                    </div>
               
               
                </div>
                <hr  style={{color:'white',width:'100%'}}/>
               

                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Stop Light </h5>
                    </div>
                    <div style={{paddingLeft:'160px'}}></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 300</h6>
                    </div>
               
               
                </div>
                <hr  style={{color:'white',width:'100%'}}/>
               

                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Brain Haemorrage </h5>
                    </div>
                    <div style={{paddingLeft:'160px'}}></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 310</h6>
                    </div>
               
               
                </div>
                <hr  style={{color:'white',width:'100%'}}/>
               
                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Black beauty </h5>
                    </div>
                    <div style={{paddingLeft:'160px'}}></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 290</h6>
                    </div>
               
               
                </div>
                <hr  style={{color:'white',width:'100%'}}/>
               
                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Lovely Rose </h5>
                    </div>
                    <div style={{paddingLeft:'160px'}}></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 260</h6>
                    </div>
               
               
                </div>
               
                 
               
             </div>
        </div>

        

        <div className='menu1'>
             <h2> Blended Whisky</h2>
              <br/>
              
             <div className='menu11'>
                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Maker's Mark </h5>
                    </div>
                    <div style={{paddingLeft:'160px'}}></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 490</h6>
                    </div>
               
               
                </div>
                <hr  style={{borderTop:'1px dotted gray',width:'100%'}}/>
               
                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Chivas regal 12years </h5>
                    </div>
                    <div style={{paddingLeft:'160px'}}></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 460</h6>
                    </div>
               
               
                </div>
                <hr  style={{color:'white',width:'100%'}}/>
               

                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Johnnie Walker Black Label </h5>
                    </div>
                    <div style={{paddingLeft:'160px'}}></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 460</h6>
                    </div>
               
               
                </div>
                <hr  style={{color:'white',width:'100%'}}/>
               

                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Johnnie Walker Red label </h5>
                    </div>
                    <div style={{paddingLeft:'160px'}}></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 280</h6>
                    </div>
               
               
                </div>
                <hr  style={{color:'white',width:'100%'}}/>
               
                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Jim Beam </h5>
                    </div>
                    <div style={{paddingLeft:'160px'}}></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 260</h6>
                    </div>
               
               
                </div>
                <hr  style={{color:'white',width:'100%'}}/>
               
                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Teachers highland </h5>
                    </div>
                    <div style={{paddingLeft:'160px'}}></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 195</h6>
                    </div>
               
               
                </div>
               
               
                
               
               
             </div>
        </div>
       

        </div>


       <br/>
       <br/>

        <div className='m-photos'>

                <div className='m-photos1'>
                    <div className='m-photos11'>
                    <img src={imgg1}/>
                    </div>
                  

                    <div className='m-photos11'>
                    <img src={imgg2}/>
                    </div>


                    <div className='m-photos11'>
                    <img src={imgg3}/>
                    </div>
                  
                </div>
               </div>
           
               <br/>
  <br/>

               <div className='m-menu'>

  
 

<div className='menu1'>

     <h2> VEG STARTERS</h2>
      <br/>
      
     <div className='menu11'>
        <div className='menu111'>
            <div className='m-1'>
            <h5 style={{color:'gray'}}> Mexican Finger Chips</h5>
            </div>
            <div style={{paddingLeft:'160px'}}></div>
            <div className='m-2'>
            <h6 style={{color:'gray'}}> Rs. 170</h6>
            </div>
       
       
        </div>
        <hr  style={{color:'white',width:'100%'}}/>
       
        <div className='menu111'>
            <div className='m-1'>
            <h5 style={{color:'gray'}}> Mexican Nachos </h5>
            </div>
            <div style={{paddingLeft:'160px'}}></div>
            <div className='m-2'>
            <h6 style={{color:'gray'}}> Rs. 190</h6>
            </div>
       
       
        </div>
        <hr  style={{color:'white',width:'100%'}}/>
       

        <div className='menu111'>
            <div className='m-1'>
            <h5 style={{color:'gray'}}> Momo </h5>
            </div>
            <div style={{paddingLeft:'160px'}}></div>
            <div className='m-2'>
            <h6 style={{color:'gray'}}> Rs. 180</h6>
            </div>
       
       
        </div>
        <hr  style={{color:'white',width:'100%'}}/>
       

        <div className='menu111'>
            <div className='m-1'>
            <h5 style={{color:'gray'}}> Crunchy Baby Corn </h5>
            </div>
            <div style={{paddingLeft:'160px'}}></div>
            <div className='m-2'>
            <h6 style={{color:'gray'}}> Rs. 180</h6>
            </div>
       
       
        </div>
        <hr  style={{color:'white',width:'100%'}}/>
       
        <div className='menu111'>
            <div className='m-1'>
            <h5 style={{color:'gray'}}> Chilli paneer </h5>
            </div>
            <div style={{paddingLeft:'160px'}}></div>
            <div className='m-2'>
            <h6 style={{color:'gray'}}> Rs. 190</h6>
            </div>
       
       
        </div>
        <hr  style={{color:'white',width:'100%'}}/>
       
        <div className='menu111'>
            <div className='m-1'>
            <h5 style={{color:'gray'}}> Mushroom Cap </h5>
            </div>
            <div style={{paddingLeft:'160px'}}></div>
            <div className='m-2'>
            <h6 style={{color:'gray'}}> Rs. 190</h6>
            </div>
       
       
        </div>
       
         
       
     </div>
</div>

<div className='menu1'>
     <h2> NON-VEG STARTERS</h2>
      <br/>
      
     <div className='menu11'>
        <div className='menu111'>
            <div className='m-1'>
            <h5 style={{color:'gray'}}> Chicken Lollopop </h5>
            </div>
            <div style={{paddingLeft:'160px'}}></div>
            <div className='m-2'>
            <h6 style={{color:'gray'}}> Rs. 240</h6>
            </div>
       
       
        </div>
        <hr  style={{borderTop:'1px dotted gray',width:'100%'}}/>
       
        <div className='menu111'>
            <div className='m-1'>
            <h5 style={{color:'gray'}}> Andhra Chilli Chicken </h5>
            </div>
            <div style={{paddingLeft:'160px'}}></div>
            <div className='m-2'>
            <h6 style={{color:'gray'}}> Rs. 240</h6>
            </div>
       
       
        </div>
        <hr  style={{color:'white',width:'100%'}}/>
       

        <div className='menu111'>
            <div className='m-1'>
            <h5 style={{color:'gray'}}> Crunchy Chicken Delight</h5>
            </div>
            <div style={{paddingLeft:'160px'}}></div>
            <div className='m-2'>
            <h6 style={{color:'gray'}}> Rs. 250</h6>
            </div>
       
       
        </div>
        <hr  style={{color:'white',width:'100%'}}/>
       

        <div className='menu111'>
            <div className='m-1'>
            <h5 style={{color:'gray'}}> Crispy Fried Chicken </h5>
            </div>
            <div style={{paddingLeft:'160px'}}></div>
            <div className='m-2'>
            <h6 style={{color:'gray'}}> Rs. 250</h6>
            </div>
       
       
        </div>
        <hr  style={{color:'white',width:'100%'}}/>
       
        <div className='menu111'>
            <div className='m-1'>
            <h5 style={{color:'gray'}}> Spicy pesto Grill Chicken </h5>
            </div>
            <div style={{paddingLeft:'160px'}}></div>
            <div className='m-2'>
            <h6 style={{color:'gray'}}> Rs. 250</h6>
            </div>
       
       
        </div>
        <hr  style={{color:'white',width:'100%'}}/>
       
        <div className='menu111'>
            <div className='m-1'>
            <h5 style={{color:'gray'}}> Greek Kabab</h5>
            </div>
            <div style={{paddingLeft:'160px'}}></div>
            <div className='m-2'>
            <h6 style={{color:'gray'}}> Rs. 250</h6>
            </div>
       
       
        </div>
       
       
        
       
       
     </div>
</div>


</div>

<br/>
<br/>

<div className='m-menu'>

  

        <div className='menu1'>
      
             <h2>SEAFOOD STARTERS</h2>
              <br/>
              
             <div className='menu11'>
                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Golden Fried prawns</h5>
                    </div>
                    <div style={{paddingLeft:'160px'}}></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 340</h6>
                    </div>
               
               
                </div>
                <hr  style={{color:'white',width:'100%'}}/>
               
                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Butter Garlic Prawns </h5>
                    </div>
                    <div style={{paddingLeft:'160px'}}></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 340</h6>
                    </div>
               
               
                </div>
                <hr  style={{color:'white',width:'100%'}}/>
               

                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Crispy Fried Fish </h5>
                    </div>
                    <div style={{paddingLeft:'160px'}}></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 330</h6>
                    </div>
               
               
                </div>
                <hr  style={{color:'white',width:'100%'}}/>
               

                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Tawa Fried Fish </h5>
                    </div>
                    <div style={{paddingLeft:'160px'}}></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 335</h6>
                    </div>
               
               
                </div>
                <hr  style={{color:'white',width:'100%'}}/>
               
                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Chinese Fish Chilly </h5>
                    </div>
                    <div style={{paddingLeft:'160px'}}></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 320</h6>
                    </div>
               
               
                </div>
                <hr  style={{color:'white',width:'100%'}}/>
               
                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> fish Kabab </h5>
                    </div>
                    <div style={{paddingLeft:'160px'}}></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 320</h6>
                    </div>
               
               
                </div>
               
                 
               
             </div>
        </div>

        <div className='menu1'>
             <h2> ITALIAN MAIN COURSE</h2>
              <br/>
              
             <div className='menu11'>
                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Grilled Fish Fillet </h5>
                    </div>
                    <div style={{paddingLeft:'160px'}}></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 390</h6>
                    </div>
               
               
                </div>
                <hr  style={{borderTop:'1px dotted gray',width:'100%'}}/>
               
                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Fish And Chips </h5>
                    </div>
                    <div style={{paddingLeft:'160px'}}></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 380</h6>
                    </div>
               
               
                </div>
                <hr  style={{color:'white',width:'100%'}}/>
               

                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Stroganoff Chicken</h5>
                    </div>
                    <div style={{paddingLeft:'160px'}}></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 375</h6>
                    </div>
               
               
                </div>
                <hr  style={{color:'white',width:'100%'}}/>
               

                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> chicken steak </h5>
                    </div>
                    <div style={{paddingLeft:'160px'}}></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 380</h6>
                    </div>
               
               
                </div>
                <hr  style={{color:'white',width:'100%'}}/>
               
                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Fish Steak </h5>
                    </div>
                    <div style={{paddingLeft:'160px'}}></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 395</h6>
                    </div>
               
               
                </div>
                <hr  style={{color:'white',width:'100%'}}/>
               
                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Moosa-la-Li-Mosi With Garlic Rice </h5>
                    </div>
                    <div style={{paddingLeft:'160px'}}></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 380</h6>
                    </div>
               
               
                </div>
               
               
                
               
               
             </div>
        </div>
       

        </div>


        <br/>
        <br/>
        <br/>



        <div className='m-menu'>

  

        <div className='menu1'>
      
             <h2>VEG MAIN COURSE</h2>
              <br/>
              
             <div className='menu11'>
                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Paneer Tikka Butter Masala</h5>
                    </div>
                    <div style={{paddingLeft:'160px'}}></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 260</h6>
                    </div>
               
               
                </div>
                <hr  style={{color:'white',width:'100%'}}/>
               
                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Mushroom do Pyaza </h5>
                    </div>
                    <div style={{paddingLeft:'160px'}}></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 240</h6>
                    </div>
               
               
                </div>
                <hr  style={{color:'white',width:'100%'}}/>
               

                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Palak Paneer </h5>
                    </div>
                    <div style={{paddingLeft:'160px'}}></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 240</h6>
                    </div>
               
               
                </div>
                <hr  style={{color:'white',width:'100%'}}/>
               

                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Aloo Mattar </h5>
                    </div>
                    <div style={{paddingLeft:'160px'}}></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 200</h6>
                    </div>
               
               
                </div>
                <hr  style={{color:'white',width:'100%'}}/>
               
                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Yellow Dal Tadka </h5>
                    </div>
                    <div style={{paddingLeft:'160px'}}></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 180</h6>
                    </div>
               
               
                </div>
                <hr  style={{color:'white',width:'100%'}}/>
               
                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> DAL FRY </h5>
                    </div>
                    <div style={{paddingLeft:'160px'}}></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 180</h6>
                    </div>
               
               
                </div>
               
                 
               
             </div>
        </div>

        <div className='menu1'>
             <h2> NON-VEG MAIN COURSE</h2>
              <br/>
              
             <div className='menu11'>
                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Chicken Tikka Masala</h5>
                    </div>
                    <div style={{paddingLeft:'160px'}}></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 280</h6>
                    </div>
               
               
                </div>
                <hr  style={{borderTop:'1px dotted gray',width:'100%'}}/>
               
                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Butter Chicken </h5>
                    </div>
                    <div style={{paddingLeft:'160px'}}></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 260</h6>
                    </div>
               
               
                </div>
                <hr  style={{color:'white',width:'100%'}}/>
               

                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Kadai Murgh</h5>
                    </div>
                    <div style={{paddingLeft:'160px'}}></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 250</h6>
                    </div>
               
               
                </div>
                <hr  style={{color:'white',width:'100%'}}/>
               

                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}>Chicken Kalimirch </h5>
                    </div>
                    <div style={{paddingLeft:'160px'}}></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 250</h6>
                    </div>
               
               
                </div>
                <hr  style={{color:'white',width:'100%'}}/>
               
                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Chettinad Chicken </h5>
                    </div>
                    <div style={{paddingLeft:'160px'}}></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 260</h6>
                    </div>
               
               
                </div>
                <hr  style={{color:'white',width:'100%'}}/>
               
                <div className='menu111'>
                    <div className='m-1'>
                    <h5 style={{color:'gray'}}> Mutton Do Pyaza </h5>
                    </div>
                    <div style={{paddingLeft:'160px'}}></div>
                    <div className='m-2'>
                    <h6 style={{color:'gray'}}> Rs. 330</h6>
                    </div>
               
               
                </div>
               
               
                
               
               
             </div>
        </div>
       

        </div>


        </div>


    </>
  )
}

export default menucontainer