import ContactoC from '../../components/contacto/contactoC';

function contacto() {
  return (
    <>
      <ContactoC />
    </>
  );
}

export default contacto;
