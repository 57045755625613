import Menucontainer from "../../components/menu/menucontainer";
import '../../components/menu/menu.css'

function menu() {
  return (
    <div>
      <Menucontainer/>
    </div>
  );
}

export default menu;