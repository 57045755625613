import React from 'react';



import img1 from '../../Assets/g1.jpg';
import img2 from '../../Assets/g5.jpg';
import img3 from '../../Assets/g3.jpeg';
import img4 from '../../Assets/g4.jpg';
import img5 from '../../Assets/g6.jpg';
import img6 from '../../Assets/g7.jpg';
import img7 from '../../Assets/g8.jpg';
import img8 from '../../Assets/g9.jpg';
import img9 from '../../Assets/g12.jpeg';
import img10 from '../../Assets/g11.jpeg';
import img11 from '../../Assets/g10.jpeg';
import img12 from '../../Assets/g16.jpeg';



export const Gallery1 = () => {

    




  return (
    <>
    <br/>
    <br/>
    <br/>

    <div className='g-all'>

    <div className=" titulo-seccion ">
                            <h1 style={{textAlign:'center'}}>Gallery</h1>
                        </div>

        <div className='g-parent'>
            
        <div className='g-photo'>
                <img style={{borderRadius:'30px'}} src={img1} />
            </div>

            <div className='g-photo'>
                <img style={{borderRadius:'30px'}} src={img2} />
            </div>

            <div className='g-photo'>
                <img style={{borderRadius:'30px'}} src={img3}/>
            </div>

            <div className='g-photo'>
                    <img style={{borderRadius:'30px'}} src={img4} />
                </div>
            

           
        </div>


        <div className='g-parent'>
            
            <div className='g-photo'>
                    <img style={{borderRadius:'30px'}} src={img5} />
                </div>
    
                <div className='g-photo'>
                    <img style={{borderRadius:'30px'}} src={img6} />
                </div>
    
                <div className='g-photo'>
                    <img style={{borderRadius:'30px'}} src={img7} />
                </div>

                <div className='g-photo'>
                    <img style={{borderRadius:'30px'}} src={img8}/>
                </div>
                
    
               
            </div>

            <div className='g-parent'>
            
            <div className='g-photo'>
                    <img style={{borderRadius:'30px'}} src={img9} />
                </div>
    
                <div className='g-photo'>
                    <img style={{borderRadius:'30px'}} src={img10} />
                </div>
    
                <div className='g-photo'>
                    <img style={{borderRadius:'30px'}} src={img11} />
                </div>

                <div className='g-photo'>
                    <img style={{borderRadius:'30px'}} src={img12}/>
                </div>
                
    
               
            </div>



           


         
    </div>
    </>
  )
}
