import Carousel from 'react-bootstrap/Carousel';
import './Testimonial.css';

function Testimonial() {
    return (
        <>
        <br/>
        <br/>
            <section id="events" className="events">
                <div className=" container ">
                    <div className=" section-title ">
                        <div className=" titulo-seccion ">
                            <h1 style={{textAlign:'center'}}>Testimonial</h1>
                        </div>
                      
                    </div>
                </div>
                <Carousel>

                    <Carousel.Item>
                        <div className="pad row slider slider-eventos">
                            <div className="row align-items-center">
                                
                                <div className="col-md">
                                    <div className="des09 text-lg-center">
                                        <h4 style={{color:'#D3D3D3'}} >Ishita chowdhury</h4>
                                        <p style={{color:'#D3D3D3'}} >B1 Pub is my go-to spot for a great night out! The ambiance is amazing, with just the right mix of energy and relaxation. I love the North Indian flavors, and their Italian dishes are just as delicious. Plus, the bar menu has a fantastic variety that never disappoints. Highly recommend it to anyone looking for a great place to unwind!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>

                    <Carousel.Item>
                        <div className="pad row slider slider-eventos">
                            <div className="row align-items-center">
                                
                                <div className="col-md">
                                    <div className="des09 text-lg-center">
                                        <h4 style={{color:'#D3D3D3'}} >Rithik Kumar</h4>
                                        <p style={{color:'#D3D3D3'}} >Every visit to B1 Pub has been a delightful experience. The food is fantastic—especially their North Indian options—and the drinks are top-notch. The atmosphere is vibrant but also relaxed, making it perfect for both a fun night with friends or a cozy date night. The location is super convenient, too!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>


                    <Carousel.Item>
                        <div className="pad row slider slider-eventos">
                            <div className="row align-items-center">
                                
                                <div className="col-md">
                                    <div className="des09 text-lg-center">
                                        <h4 style={{color:'#D3D3D3'}} >Reji John</h4>
                                        <p style={{color:'#D3D3D3'}}  className="mb-0">I’ve tried several pubs in the area, but B1 Pub really stands out. The ambiance is welcoming, and the service is great. The mix of North Indian and Italian food is spot on, and there’s always something new to try from the bar. Located just opposite Canara Bank, it’s easy to find, and it’s a perfect spot to chill with friends!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>

                    <Carousel.Item>
                        <div className="pad row slider slider-eventos">
                            <div className="row align-items-center">
                                
                                <div className="col-md">
                                    <div className="des09 text-lg-center">
                                        <h4 style={{color:'#D3D3D3'}} >Ajeet Sharma</h4>
                                        <p style={{color:'#D3D3D3'}} className="mb-0">Great place for locals to hang out and chill.
                                            Great nachos and egg chilli here.
                                            Service is great here. All staff members are cool here.
                                            It has seperate smoking area and non smoking area.
                                            Always sports events are displayed on projectors.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>
                    
                   


                </Carousel>
            </section>
        </>
    );
}

export default Testimonial;